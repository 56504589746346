import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: localStorage.getItem('language') || 'en',
    // 登录状态
		loginStatus: localStorage.getItem('loginStatus') || false,
		// token
		token:null,
		// 用户信息
		uid:0,
    //用户登录时间
    userLoginTime:0,
    //用户信息
    userInfo:{}
  },
  getters: {
  },
  mutations: {

    changeLanguage(state) {
      state.language = state.language === 'cn' ? 'en' : 'cn'
      localStorage.setItem('language', state.language)
    },// 登录
		login(state,data){
			state.uid = data.uid
			state.loginStatus = true
			state.token = data.token
      state.userInfo = data.userInfo
      state.userLoginTime = (new Date()).getTime()
      
			// // 持久化存储
      localStorage.setItem('token', state.token)
      localStorage.setItem('uid', state.uid)
      localStorage.setItem('userLoginTime', state.userLoginTime)
      localStorage.setItem('username', state.userInfo.username)
      localStorage.setItem('loginStatus', true)
			// uni.setStorageSync('userInfo',JSON.stringify(userinfo))
		},
		// 退出登录
		logout(state){
			state.uid = 0
			state.loginStatus = false
			state.token = null
			localStorage.removeItem('token')
      localStorage.removeItem('uid')
      localStorage.removeItem('username')
      localStorage.removeItem('userLoginTime')
      localStorage.setItem('loginStatus', false)
		},


  },
  actions: {
  },
  modules: {    
  }
})
