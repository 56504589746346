<script>
export default {
  name: "FootView",
  computed:{
    language() {
      return this.$store.state.language
    }
  },
}
</script>

<template>
  <div class="g-foot">
    <template v-if="language==='cn'">
      <p>Copyright（C）2024第三届国际农业生物多样性大会 版权所有</p>
      <!-- <p>京ICP备120889604号-2</p> -->
    </template>
    <template v-else>
      <p>Copyright (C) 2024 The Third International Agrobiodiversity Congress. All rights reserved</p>
      <!-- <p>Beijing ICP No. 120889604-2</p> -->
    </template>
  </div>
</template>

<style scoped lang="scss">
@import "../assets/css/page.scss";
.g-foot{background: #0A8B51; text-align: center; padding: .4rem 0;
  font-size: .2rem;
  color: #FFFFFF;
  line-height: .38rem;
  @include media('phone'){font-size: .24rem}
}
</style>
