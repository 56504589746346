<script>
import AOS from 'aos'
export default {
  props: {
    curr: {
      type: Number,
      default: 0,
    },
    title: {
      type: Object,
      default:()=>{},
    },
  },
  data() {
    return {
      isPhone: window.innerWidth <= 800,
      navShow: false,
      navListOpenCurr: null,
      navList: [
        {name: '首页', en_name: 'HOME', url: '/'},
        {name: '大会介绍', en_name: 'INTRODUCTION', url: '/about'},
        {name: '组织机构', en_name: 'organizers', url: '/group'},
        {name: '大会议程', en_name: 'programs', url: '/schedule'},
        {name: '嘉宾报告', en_name: 'speakers', url: '/reporter'},
        {name: '展览展示', en_name: 'exhibition', url: '/exhibi'},
        {name: '参会注册', en_name: 'registration', url: '/login'},
        {name: '会场住宿', en_name: 'venues', url: '/venue',
          list: [
            {name: '住宿交通', en_name: 'hotels', url: '/hotal'},
            {name: '会议场地', en_name: 'venues', url: '/venue'},
          ]
        },
        {name: '通知下载', en_name: 'notices', url: '/news',
          list: [
            {name: '新闻通知', en_name: 'news', url: '/news'},
            {name: '文档下载', en_name: 'download', url: '/down'},
          ]
        },
        {name: '联系我们', en_name: 'contact', url: '/contact'},
      ]
    }
  },
  computed:{
    language() {
      return this.$store.state.language
    }
  },
  mounted() {
    this.autoLoginStatus()
    AOS.init({
      duration: 1000
    })
  },
  beforeDestroy() {
    AOS.refresh()
  },
  methods:{
    autoLoginStatus()
    {
      let userLoginTime = localStorage.getItem('userLoginTime')
      let nowTime = (new Date()).getTime()      
      if(userLoginTime){        
        //计算登录时间为多少分钟
        let onlineTime = (nowTime-parseInt(userLoginTime))/(1000*60)
        //登录时间小于60分钟,说明登录没有失效
        if(onlineTime < 60*3){
          localStorage.setItem('loginStatus', true)
        }else{
          localStorage.setItem('loginStatus', false)
          this.$store.commit('logout')
          window.location.href = '/'
          
        }  
        // console.log('onlineTime',onlineTime)
      }      
      // console.log('userLoginStatus',localStorage.getItem('loginStatus'))
    },
    changeLanguage(){
      this.$store.commit('changeLanguage')
    },
    openNav(i){
      if(i === this.navListOpenCurr){
        this.navListOpenCurr = null
      }else{
        this.navListOpenCurr = i
      }
    }
  }
}
</script>

<template>
  <div>
    <div class="g-head" :class="{'en': language==='en'}">
      <div class="head">
        <div class="wal">
          <h2 class="title">{{title[language]}}</h2>
          <div class="navA" @click="navShow = !navShow"></div>
          <div class="g-nav" :class="{'show': navShow}">
            <ul>
              <li v-for="(item, index) in navList" :key="index" :class="{'open': navListOpenCurr === index}">
                <router-link :to="{ path: item.url }" class="alink" :class="{'on': curr === index}">
                      {{language === 'cn' ? item.name : item.en_name}}
                </router-link>
                <div class="arrow" v-if="item.list && isPhone" @click="openNav(index)"></div>
                <div class="list" v-if="item.list">
                  <dl>
                    <dd v-for="_item in item.list" :key="_item.id">
                      <router-link :to="{ path: _item.url }" class="alink">
                        {{language === 'cn' ? _item.name : _item.en_name}}
                     </router-link>
                    </dd>
                  </dl>
                </div>
              </li>
            </ul>
          </div>
          <div class="language"><a href="javascript:" @click="changeLanguage">{{language === 'cn'?'EN': '中文'}}</a></div>
        </div>
      </div>
    </div>
    <div class="page-banner" :class="{'en': language==='en'}">
      <img src="@/assets/image/banner.jpg" class="bg" alt="">
      <div class="wal">
        <div class="logo" ><img src="@/assets/image/logo.png" alt=""></div>
        <template v-if="language === 'cn'">
          <h2 >第三届国际农业生物多样性大会</h2>
          <div class="time" >
            中国云南昆明<br>
            2025年4月22 日– 25日
          </div>
        </template>
        <template v-else>
          <h2  v-if="!isPhone"><img src="@/assets/image/img1208.png" alt=""></h2>
          <h2  v-else>AGROBIODIVERSITY FOR PEOPLE AND PLANET</h2>
          <div class="time" >
            22 – 25 April 2025<br>
            Kunming, Yunnan, China
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/css/page.scss";
.g-head{
  .head{background: #0A8B51; padding: 0 .44rem 0 .3rem; position: relative; z-index: 50;}
  .wal{height: .8rem; max-width: 100%; position: relative}
  @include media('pc'){
    h2.title{display: none}
  }
  @include media('phone'){
    height: 1rem;
    .head{position: fixed; left: 0; top: 0; right: 0; padding: 0; background: #11A965}
    .wal{height: 1rem}
    h2.title{text-align: center; color: #fff; line-height: 1rem; font-size: .32rem; text-transform: uppercase}
    .navA{position: absolute; z-index: 5; top: 0; bottom: 0; right: -.25rem; width: 1rem; background: url("@/assets/image/img48.png") center no-repeat; background-size: .48rem}
  }
  .g-nav{
    @include media('pc'){
      padding-right: 1.6rem;
      ul{display: flex; justify-content: space-between}
      li{position: relative;
        .alink{display: flex; align-items: center; padding: 0 .25rem; font-size: .22rem; color: #fff; height: .8rem; min-width: 1.3rem; justify-content: center; transition: background-color .5s; text-transform: uppercase;
          &.on,
          &:hover{background: #037B45}
        }
        .list{position: absolute; left: 0; top: 100%; background: #037B45; width: 2.1rem; padding: .15rem 0; opacity: 0; visibility: hidden; pointer-events: none; transition: .5s;
          a{display: flex; align-items: center; padding: 0 .25rem; position: relative; color: #fff; font-size: .22rem; height: .6rem;
            &:after{content: '\e667'; font-family: 'iconfont'; position: absolute; top: 50%; right: .2rem; transform: translateY(-50%) scale(.7)}
          }
        }
        &:hover{
          .alink{background: #037B45}
          .list{opacity: 1; visibility: visible; pointer-events: auto}
        }
      }
    }
    @include media('phone'){
      position: fixed; z-index: 49; left: 0; right: 0; top: 1rem; background: #0F8651; bottom: 0;
      transform: translateX(100%); transition: .5s; padding: 0 .3rem;
      &.show{transform: translateX(0)}
      li{border-bottom: #1D8E5C solid 1px; text-align: center; position: relative;
        a{display: block; color: #fff}
        .arrow{position: absolute; z-index: 5; top: 0; right: 0; height: .92rem; width: 1rem; display: flex; align-items: center; justify-content: center; font-family: 'iconfont'; color: #fff; font-size: .24rem; transition: .5s;
          &:after{content: '\e6cb'; opacity: .7}
        }
        >a{font-size: .28rem; padding: 0 .5rem; line-height: .92rem; text-transform: uppercase}
        .list{position: relative; top: 1px; z-index: 5; display: none;
          dl{display: grid; grid-gap: .24rem}
          dd a{font-size: .24rem; line-height: .8rem; background: #037B45}
        }
      }
      li.open{
        .arrow{transform: rotate(180deg)}
        .list{display: block}
      }
    }
  }
  .language{position: absolute; top: 50%; transform: translateY(-50%);
    @include media('pc'){
      right: 0;
    }
    a{display: flex; align-items: center; justify-content: center; transition: .5s;
      width: .8rem;
      height: .38rem;
      background: #fff;
      border-radius: .3rem; font-size: .2rem; color: #0A8B51;
      &:hover{background: #037B45; color: #fff}
    }
    @include media('phone'){
      left: 0;
    }
  }
}
.g-head.en{
  .g-nav{
    @include media('pc'){
      li{
        .alink{font-size: .2rem; font-weight: 600;}
        .list{width: 3.4rem}
      }
    }
  }
}
.page-banner{position: relative;
  .bg{position: absolute; left: 0; top: 0; width: 100%; display: block; height: 3.2rem}
  .wal{height: 3.2rem; max-width: 100%; position: relative; z-index: 5; display: flex; flex-direction: column; align-items: center; color: #fff; padding-top: .5rem;}
  .logo{width: 1.2rem; position: absolute; left: .85rem; top: .55rem}
  h2{font-size: .62rem; text-shadow: 0px .03rem .1rem rgba(81,131,204,0.44); line-height: 1em;
    width: 10.29rem;display: flex; align-items: center; justify-content: center;
    @include media('pc'){
      height: .73rem;
      background: url("@/assets/image/img1029.png") center no-repeat; background-size: 100% 100%;
    }
  }
  .time{font-size: .28rem; line-height: .42rem; width: 5.44rem; height: 1rem; text-align: center; background: url("@/assets/image/img544.png") center no-repeat; background-size: 100% 100%; display: flex; flex-direction: column; align-items: center; justify-content: center; margin-top: .42rem;}
  @include media('phone'){
    .logo{width: 1rem;left: 0; top: .6rem}
    h2{font-size: .52rem; line-height: .6rem; width: 4.65rem; text-align: center}
  }
}
.page-banner.en{
  .time{font-size: .32rem; line-height: .42rem; font-weight: 200;}
  @include media('pc'){
    h2{background: none; width: 12.08rem; height: auto;}
  }
  @include media('phone'){
    .wal{padding-top: .3rem}
    h2{font-size: .48rem; line-height: .5rem;}
    .time{margin-top: .22rem;}
  }
  
}
</style>
