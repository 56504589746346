<template>
  <div :class="{'en': language==='en'}">
    <HeaderView :curr="0" :title="{cn: '大会首页', en: 'HOME'}"></HeaderView>
    <div class="index-login" v-if="userLoginStatus">
      <div class="wal">
        <div class="title">
          <img src="@/assets/image/img26.png" alt="" class="ico">
          <h2>{{language === 'cn'?'用户登录' : 'USER LOGIN'}}</h2>
        </div>
        <div class="usertext"> Welcome <strong>  {{ userLoginUsername }}</strong>  to login! </div>
        <router-link to="/uc/info" class="submit ucbtn">{{language === 'cn'?'用户中心':'User Center'}}</router-link>
        <div class="btn" @click="loginOut()">{{language === 'cn'?'退出登录':'LOGOUT'}}</div>
        
      </div>
    </div>
    <div class="index-login" v-else>
      <div class="wal">
        <div class="title">
          <img src="@/assets/image/img26.png" alt="" class="ico">
          <h2>{{language === 'cn'?'用户登录' : 'USER LOGIN'}}</h2>
        </div>
        <div class="input"><input type="text" :placeholder="language === 'cn'?'邮箱':'Email'" v-model="username"></div>
        <div class="input"><input type="password" :placeholder="language === 'cn'?'密码':'Password'" v-model="password"></div>
        <div class="submit" @click="login()">{{language === 'cn'?'登录':'LOGIN'}}</div>
        <router-link to="/reg" class="btn">{{language === 'cn'?'注册':'REGISTRATION'}}</router-link>
      </div>
    </div>
    <div class="index-about">
      <div class="wal">
        <div class="page-title"  >
          <h2>{{language === 'cn'? '大会介绍':'CONGRESS INTRODUCTION' }}</h2>
        </div>
        <div class="content"   v-if="language === 'cn'">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{aboutInfo.description}}
          <router-link :to="{ path: '/about' }">更多 ></router-link>
        </div>
        <div class="content" v-else>
          {{aboutInfo.description_en}} 
          <router-link :to="{ path: '/about' }">More ></router-link>
        </div>
        <!-- <div class="more"  >
          <router-link :to="{ path: '/about' }">{{ language==='cn'?'更多 >':'More >' }}</router-link>
        </div> -->
      </div>
    </div>
    <div class="index-date">
      <div class="wal"  >
        <template v-if="language==='cn'">
          <div class="page-title page-title-white">
            <h2>重要日期</h2>
          </div>
          <ul v-html="importDate.content">            
          </ul>
        </template>
        <template v-else>
          <div class="page-title page-title-white">
            <h2>IMPORTANT DATES</h2>
          </div>
          <ul v-html="importDate.content_en">            
          </ul>
        </template>
      </div>
    </div>
    <div class="index-notice">
      <div class="wal">
        <div class="page-title"  >
          <h2>{{ language==='cn'?'新闻通知':'NEWS & NOTICES' }}</h2>
        </div>
        <div class="list">
          <ul>
            <li v-for="item in notice" :key="item.id">
              <router-link :to="{ path: '/detail', query: { id: item.id } }">
                <div class="name">{{language==='cn'?item.name:item.en_name}}</div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="index-report">
      <div class="wal">
        <div class="page-title"  >
          <h2>{{ language==='cn'?'大会报告人':'KEYNOTE SPEAKERS' }}</h2>
        </div>
        <div class="list"  >
          <div class="list-wrapper" id="report">
            <ul class="swiper-wrapper">
              <li class="swiper-slide" v-for="(list, index) in reporter" :key="index">
                <dl>
                  <dd v-for="item in list" :key="item.id">
                    <a href="" class="item">
                      <div class="img"><img :src="baseDomain+item.img" alt=""></div>
                      <div class="name">{{language === 'cn'?item.name:item.en_name}}</div>
                    </a>
                  </dd>
                </dl>
              </li>
            </ul>
          </div>
          <div class="dots">
            <div v-for="(dot, index) in reporter" :key="index">
              <span ref="reportDot" :class="{'on': index === reportCurr}">{{dot.id}}</span>
            </div>
          </div>
        </div>
        <div class="more"  >
          <router-link :to="{ path: '/reporter' }">{{ language==='cn'?'更多 >':'More >' }}</router-link>
        </div>
      </div>
    </div>
    <div class="index-organizers">
      <div class="wal">
        <div class="title"  >
          <h2>{{ language==='cn'?'会议主办方':'ORGANIZERS' }}</h2>
        </div>
        <div class="list">
          <ul>
            <li v-for="item in unit[language]" :key="item.id"  >
              <h5>{{item.title}}</h5>
              <dl>
                <dd v-for="_item in item.list" :key="_item.id">{{_item.name}}</dd>
              </dl>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="index-unit">
      <div class="wal">
        <div class="page-title"  >
          <h2>{{ language==='cn'?'合作机构':'PARTNERS' }}</h2>
        </div>
        <div class="list">
          <ul>
            <li v-for="item in organizers" :key="item.id"  >
              <div class="img"><img :src="baseDomain+item.img" alt=""></div>
            </li>
           
          </ul>
          <!-- <div class="more"  ><a href="">UNEP-IEMP ></a></div> -->
        </div>
      </div>
    </div>
    <FootView></FootView>
  </div>
</template>

<script>
import Swiper from 'swiper';
import HeaderView from "@/components/HeadView.vue";
import FootView from "@/components/FootView.vue";
import AOS from "aos";
import _ from 'lodash';
export default {
  components: {HeaderView, FootView},
  data() {
    return{
      isPhone: window.innerWidth <= 800,
      aboutInfo:{'description':'','description_en':''},
      importDate:{'content':'','content_en':''},
      notice: [],
      reporterData: [ ],
      reporter: [],
      reportCurr: 0,
      unit: {},
      userLoginStatus: eval(localStorage.getItem('loginStatus')),
      userLoginUsername: localStorage.getItem('username'),
      username:'',
      password:'',
      organizers: [
        // {img: './image/img95_1.png'},        
      ]
    }
  },
  computed:{
    language() {
      return this.$store.state.language
    }
  },
  mounted() {    
    this.getDataList()
    // console.log(this.userLoginStatus)
    AOS.init({
      duration: 1000
    })
    
  },
  beforeDestroy() {
    AOS.refresh()
  },
  watch: {},
  methods:{    
    loginOut(){
      let that = this
      window.layer.confirm('Are you sure you want to proceed?', {icon: 3, title:'Tip',btn: ['Ok', 'Cancel']}, function(index){
          that.userLoginStatus = false
          that.$store.commit('logout')
          window.layer.close(index);
          // console.log('确定',that.userLoginStatus)
      });
    },
    login()
    {
      let that = this
      let postData = {'username':this.username,'password':this.password,}
      if(postData.username == ''){
          window.layer.msg('用户名不能为空')
					return false
      }
      if(postData.password == ''){
          window.layer.msg('密码不能为空')
					return false
      }
      this.$http.post(that.baseDomainUcUrl+'auth/login',postData)
                .then( (res) => { 
                  if(res.data.code == 200){                   
                    that.$store.commit('login',res.data.data)
                    this.userLoginStatus = true
                    this.userLoginUsername = localStorage.getItem('username')
                    
                    console.log('datalist:',res.data)
                    console.log('token:',that.$store.state.token)
                    console.log('localStorage token:',localStorage.getItem('token'))
                  }else{
                    window.layer.msg(res.data.msg)
                  }  
                }).catch( (err) => {
                  console.log(err)
                });
    
    },
    getDataList(){
      //获取首页数据
      let that = this
      this.$http.get(that.baseDomainUrl+'home/index')
                .then( (res) => { 
                  this.aboutInfo = res.data.data.about
                  this.importDate = res.data.data.importDate
                  this.notice = res.data.data.news
                  this.unit = res.data.data.company
                  this.organizers = res.data.data.org
                  // console.log('datalist:',res.data.data)
                }).catch( (err) => {
                  console.log(err)
                });
      //获取报告人数据          
      this.$http.get(that.baseDomainUrl+'home/report')
                .then( (res) => { 
                  this.reporterData = res.data.data                  
                  this.reporterFun()
                  // console.log('reporterData:',this.reporterData)
                  // console.log('report:',res.data.data)
                }).catch( (err) => {
                  console.log(err)
                });
    },    
    reporterFun()
    {
      const list = this.reporterData
      this.reporter = _.chunk(list, this.isPhone ? 4 : 5)
      this.$nextTick(()=>{
        const swiper = new Swiper('#report', {
          slidesPerView: 1,
          speed: 600,
          loop: true,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          on:{
            slideChange: (swiper)=>{
              this.reportCurr = swiper.activeIndex
            }
          }
        })
        this.$refs['reportDot']?.forEach((item, index)=>{
          item.addEventListener('click', ()=>{
            swiper.slideTo(index)
          })
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/page";

.index-about .content{
  text-align: left;
  //-webkit-box-orient: vertical; overflow: hidden; -webkit-line-clamp:3;text-overflow: ellipsis;display: -webkit-box;
}

.index-login .usertext{margin-right: 0.27rem; min-width: 3.28rem;font-size: .22rem; 
  strong{color: #f60;}
}
.en .index-login .ucbtn{width: 1.8rem;}
.index-login .red{background: #f00;}

.index-report{min-height: 3.8rem;}

.index-about .content{ font-size: 0.22rem; }

.index-organizers .list li dd{font-size: .22rem;}

.en .index-organizers .title h2{font-family: "helvetica";padding-left: 0.1rem;letter-spacing: 0;font-size: 0.38rem;}
.en .index-organizers .list li h5{font-family: "helvetica";letter-spacing: 0;}


</style>
